import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  connect() {


    var filter_btn = document.getElementById("filter_btn");
    if(filter_btn){filter_btn.addEventListener("click", updateAllFilters)}; 

    function updateAllFilters() {
      let checkboxes2 = document.querySelectorAll('.filter_cb');
     /*  console.log(checkboxes2); */
      var checkedValues = [];


      for(let i = 0; i < checkboxes2.length; i++){
        if(i > 0) {
          if (checkboxes2[i].name != checkboxes2[i-1].name) {
            checkedValues = [];
          }
        }

        if(checkboxes2[i].checked == true) {
            checkedValues.push(checkboxes2[i].value);
            
            checkboxes2[i].parentNode.parentNode.parentNode.getElementsByClassName("selected-items")[0].innerHTML = checkedValues;
            checkboxes2[i].parentNode.parentNode.parentNode.parentNode.getElementsByClassName("badge")[0].title = checkedValues;
            checkboxes2[i].parentNode.parentNode.parentNode.getElementsByClassName("selected-items")[0].title = checkedValues; 
            if(checkedValues.length == 0){
              checkboxes2[i].parentNode.parentNode.parentNode.parentNode.getElementsByClassName("badge")[0].innerHTML = "";
            } else {
              checkboxes2[i].parentNode.parentNode.parentNode.parentNode.getElementsByClassName("badge")[0].innerHTML = checkedValues.length;
            }
        }
        
        
      }
      
      
    }
    
    var dropdownArr = document.getElementsByClassName("dropdown-check-list");
    for(let i = 0; i < dropdownArr.length; i++) {
      dropdownArr[i].getElementsByClassName("anchor")[0].addEventListener("click", toggleDropdown);
    }


    function toggleDropdown() {
      if (this.parentNode.classList.contains('visible'))
          this.parentNode.classList.remove('visible');
      else
          this.parentNode.classList.add('visible');
    }



    // Multiple values dropdown starts here
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for(let i = 0; i < checkboxes.length; i++){
      if(checkboxes[i].parentNode.parentNode.parentNode.getElementsByClassName("selected-items")[0]) {
        checkboxes[i].addEventListener('click', updateDropdown);
      }
    }



    function updateDropdown() {
      let checkedValues = [];
      let allCheckboxes = document.getElementsByName(this.name);
      for (var i = 0; i < allCheckboxes.length; i++) {
        if(allCheckboxes[i].checked == true) {
          checkedValues.push(allCheckboxes[i].value);
        }
      }
     
      this.parentNode.parentNode.parentNode.getElementsByClassName("selected-items")[0].innerHTML = checkedValues;
      this.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("badge")[0].title = checkedValues;
      this.parentNode.parentNode.parentNode.getElementsByClassName("selected-items")[0].title = checkedValues;
      if(checkedValues.length == 0){
        this.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("badge")[0].innerHTML = "";
      } else {
        this.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("badge")[0].innerHTML = checkedValues.length;
      }
      
      
      
    }


    
    //To close all dropdowns when window is clicked.
    window.addEventListener("click", function(e) {
      if(e.target.classList.contains('anchor')){
        let parent_id = e.target.parentNode.id;
        
        for(let i = 0; i < dropdownArr.length; i++) {
          if(dropdownArr[i].id != parent_id){
            if (dropdownArr[i].classList.contains('visible')) {
              dropdownArr[i].classList.remove('visible');
            }
          } 
        }
      } else {
        if(e.target.closest(".dropdown-check-list")) {
          let dropdownCheckList_id = e.target.closest(".dropdown-check-list").id;
          for(let i = 0; i < dropdownArr.length; i++) {
            if(dropdownArr[i].id != dropdownCheckList_id){
              if (dropdownArr[i].classList.contains('visible')) {
                dropdownArr[i].classList.remove('visible');
              }
            }
          }
        } else {
          for(let i = 0; i < dropdownArr.length; i++) {
            if (dropdownArr[i].classList.contains('visible')) {
              dropdownArr[i].classList.remove('visible');
            }
          }
        }
      }
    });
      

  }
}
