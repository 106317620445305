import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-filter"
export default class extends Controller {
  connect() {
    $(document).ready(function(){
      $("#filterBtn").click(function(){
        $("#tableFilter").collapse('toggle');
      });
    });
  }
}
