import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="ticket"
export default class extends Controller {
  connect() {
    this.handleInputFocus()
    this.handleRadioChange()
  }

  handleInputFocus() {
    const inputs = document.querySelectorAll('.input-field-inq')

    inputs.forEach(inp => {
      inp.addEventListener('focus', () => {
        inp.classList.add('active')
      })

      inp.addEventListener('blur', () => {
        if (inp.value != '') return
        inp.classList.remove('active')
      })
    })
  }

  handleRadioChange() {
    const radios = document.querySelectorAll(
      "input[type='radio'][name='ticket[inquiry_type_id]']"
    )

    radios.forEach(radio => {
      radio.addEventListener('change', () => {
        const lastName = document.querySelector('#ticket_last_name')
        const lastNameLabel = document.querySelector(
          'div.col-11.col-md-6:has(#ticket_last_name) label'
        )

        if (radio.nextElementSibling.textContent.trim() == 'After Sales') {
          lastNameLabel.innerHTML = "Last Name: <span class='asterisk'>*</span>"
        } else {
          lastNameLabel.innerHTML = 'Last Name: '
        }
      })
    })
  }
}
